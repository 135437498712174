@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

:root {
  --clr-dark-purple: #9667e0;
  --clr-light-purple: #d4bbfc;
  --clr-lightest-purple: #ebd9fc;
}
nav {
  width: 100vw;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #9667e0;
  background-color: var(--clr-dark-purple);
  font-family: sans-serif;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}
nav img {
  width: 50px;
  border-radius: 50%;
}
nav h1 {
  color: white;
}
nav a {
  color: #9667e0;
  color: var(--clr-dark-purple);
  text-decoration: none;
  padding: 0.8em 1em;
  background-color: white;
  border-radius: 0.5em;
}
nav a:hover {
  background-color: lightgrey;
}

footer {
  width: 100vw;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--clr-dark-purple);
  position: static;
  bottom: 0;
  padding: 1rem;
  color: white;
}
footer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
footer ul li {
  padding: 0.5rem;
}

section {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.left {
  flex: 1 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 2rem;
}
.left h2 {
  font-size: 3rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}
.left p {
  font-family: Roboto, sans-serif;
  font-weight: 100;
  color: gray;
  margin-top: 1rem;
  line-height: 2rem;
}
.buttons {
  width: 100%;
  margin-top: 2rem;
  display: flex;
}
.buttons a {
  color: var(--clr-lightest-purple);
  font-family: sans-serif;
  padding: 1.3em 3em;
  text-decoration: none;
  background-color: var(--clr-dark-purple);
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;
}
.buttons a:nth-child(1):hover {
  background-color: rgba(150, 103, 224, 0.8);
}
.buttons a:nth-child(2):hover {
  background-color: rgba(150, 103, 224, 0.5);
}
.buttons a:nth-child(2) {
  color: var(--clr-dark-purple);
  margin-left: 1rem;
  background-color: var(--clr-lightest-purple);
}
.right {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 2rem;
}
.right img {
  width: 300px;
  border-radius: 5rem;
}

.graph-container {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
h3:nth-child(4) {
  font-size: 2.5rem;
  text-align: center;
  color: var(--clr-dark-purple);
  margin-top: 1rem;
}
hr {
  color: var(--clr-dark-purple);
}
.dashboard-split-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border: 1px solid var(--clr-dark-purple);
  border-radius: 2rem;
}
.dashboard-split-right {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border: 1px solid var(--clr-dark-purple);
  margin-left: 1rem;
  border-radius: 2rem;
}
.dashboard-split-right a {
  text-decoration: none;
  padding: 1rem;
  background-color: var(--clr-dark-purple);
  border-radius: 2rem;
  color: white;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}
.dashboard-split-right a:hover {
  background-color: var(--clr-light-purple);
}

.test-card {
  width: 200px;
  height: 200px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--clr-dark-purple);
  margin: 1rem;
}
.test-card a {
  margin-top: 1rem;
  padding: 1rem;
  text-decoration: none;
  background-color: var(--clr-dark-purple);
  border-radius: 2rem;
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}
.test-card a:hover {
  background-color: var(--clr-light-purple);
}

section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
}
.starting-cards {
  width: 350px;
  border: 1px solid var(--clr-light-purple);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 400px;
  border-radius: 2rem;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease-in-out;
}
.starting-cards:hover {
  background-color: var(--clr-lightest-purple);
  cursor: pointer;
}
.starting-cards img {
  width: 200px;
  border-radius: 50%;
}
.links {
  text-decoration: none;
  color: black;
}

.sidebar {
  width: 20vw;
  height: 95vh;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  display: flex;
  flex-direction: column;
  background-color: var(--clr-lightest-purple);
  padding: 1rem;
  overflow-y: scroll;
  float: left;
}

.sidebar h2 {
  font-weight: 500;
}

.student-card {
  width: 100%;
  height: 60px;
  display: flex;

  justify-content: flex-start;
  background-color: white;
  margin-top: 15px;
  border-radius: 1rem;
  padding: 1rem;
}
.student-card div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.student-card h2 {
  font-size: 15px;
  font-weight: 500;
}
.student-card p {
  font-weight: 100;
}
.student-card img {
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.student-card:hover,
.student-card::selection,
.student-card:focus,
.student-card:active,
.student-card::-moz-selection,
.student-card:visited {
  background-color: var(--clr-light-purple);
}
.student-links {
  text-decoration: none;
}

.small-section {
  width: 80vw;
  margin-left: 20vw;
  border-radius: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-table {
  border: 1px solid var(--clr-dark-purple);
  margin: 1rem 0.5rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.left-chart {
  flex: 3 1;
  border: 1px solid var(--clr-dark-purple);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.right-chart {
  height: 100%;
  flex: 1 1;
  border: 1px solid var(--clr-dark-purple);
  margin: 0.5rem;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.AreaChart {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-dark-purple);
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.tests-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 80vh;
  margin: auto;
}

.vc-agora {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.Form{
    width: 60vw;
    margin: auto;
}

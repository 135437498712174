.small-section {
  width: 80vw;
  margin-left: 20vw;
  border-radius: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.big-table {
  border: 1px solid var(--clr-dark-purple);
  margin: 1rem 0.5rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.left-chart {
  flex: 3;
  border: 1px solid var(--clr-dark-purple);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.right-chart {
  height: 100%;
  flex: 1;
  border: 1px solid var(--clr-dark-purple);
  margin: 0.5rem;
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.AreaChart {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-dark-purple);
  border-radius: 2rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

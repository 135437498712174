.student-card {
  width: 100%;
  height: 60px;
  display: flex;

  justify-content: flex-start;
  background-color: white;
  margin-top: 15px;
  border-radius: 1rem;
  padding: 1rem;
}
.student-card div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.student-card h2 {
  font-size: 15px;
  font-weight: 500;
}
.student-card p {
  font-weight: 100;
}
.student-card img {
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.student-card:hover,
.student-card::selection,
.student-card:focus,
.student-card:active,
.student-card::-moz-selection,
.student-card:visited {
  background-color: var(--clr-light-purple);
}
.student-links {
  text-decoration: none;
}
